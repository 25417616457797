export namespace SystemConst {

    /* ストレージキー */
    export const TOKEN_KEY = 'access_token';
    export const ROLE_KEY = 'roles';

    /* 画像幅 */
    export const MAX_WIDTH = 1280;

    /* 作業時間の枠 */
    export const TIME = [
        { value: '07:00' },
        { value: '07:30' },
        { value: '08:00' },
        { value: '08:30' },
        { value: '09:00' },
        { value: '09:30' },
        { value: '10:00' },
        { value: '10:30' },
        { value: '11:00' },
        { value: '11:30' },
        { value: '12:00' },
        { value: '12:30' },
        { value: '13:00' },
        { value: '13:30' },
        { value: '14:00' },
        { value: '14:30' },
        { value: '15:00' },
        { value: '15:30' },
        { value: '16:00' },
        { value: '16:30' },
        { value: '17:00' },
        { value: '17:30' },
        { value: '18:00' },
        { value: '18:30' },
        { value: '19:00' },
        { value: '19:30' },
        { value: '20:00' },
    ];

    /* サーバーからの戻り */
    export class ReturnCode {
        public static readonly SUCCESS = '0000';            // 正常終了
        public static readonly VERSION_ERROR = '9000';      // apiバージョン違い
        public static readonly ERROR = '9999';              // その他エラー
    }

    /* APIバージョン */
    export const API_VERSION = 'feature/custom_checklist';

}
