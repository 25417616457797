import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';

import { Storage } from '@ionic/storage';
import { JwtHelperService } from '@auth0/angular-jwt';

import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { SystemConst } from '../app-constant'

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  /* 通信先 */
  url = environment.apiUrl;

  /* ログイン状態 */
  authenticationState = new BehaviorSubject(null);

  /**
   * Constructor
   *
   * @param { HttpClient } httpClient
   * @param { Router } router
   * @param { AlertController } alertController
   * @param { Storage } storage
   * @param { JwtHelperService } jwtHelper
   */
  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private alertController: AlertController,
    private storage: Storage,
    private jwtHelper: JwtHelperService,
  ) { }

  /**
   * トークンが有効か確認
   * 有効だった時は「ログイン状態」をtrueに設定
   * 期限切れの時はストレージからトークンを削除
   *
   */
  checkToken() {
    return new Promise((resolve, reject) => {

      this.storage.get(SystemConst.TOKEN_KEY).then(token => {
        if (token) {

          // トークンがストレージに登録されている場合、有効期限を確認
          let isExpired = this.jwtHelper.isTokenExpired(token);
          if (!isExpired) {
            // 有効なトークンだった時はログイン済みとする
            this.authenticationState.next(true);
            resolve(true);
          } else {
            // 期限切れの時は未ログインとする
            this.storage.remove(SystemConst.ROLE_KEY);
            this.storage.remove(SystemConst.TOKEN_KEY);
            resolve(true);
          }

        } else {
          // トークンがストレージに登録されていない時は何もしない
          resolve(true);
        }
      })
        .catch(
          () => {
            reject(false);
          });
    });
  }

  /**
   * ログイン処理
   *
   * 成功した場合、サーバーから戻ってきたトークンをストレージに登録し、
   * 「ログイン状態」をtrueに設定に設定
   *
   * @param { any } credentials     入力情報（email、password）
   * @returns { Observable<any> }   結果
   * @throws { any }                エラーメッセージ
   */
  login(credentials: any): Observable<any> {
    return this.httpClient.post(`${this.url}/login/`, credentials)
      .pipe(
        tap(
          res => {
            this.storage.set(SystemConst.TOKEN_KEY, res['token']).then(
              () => {
                this.authenticationState.next(true);
                this.router.navigateByUrl('top');
                //this.router.navigate(['top']);
                //ここから画面を遷移しても、app.componentsが呼ばれない？！
              }
            );
          }
        ),
        catchError(
          error => {
            // エラーメッセージは画面に表示するので
            // ここではアラートは出さない
            if (error.status != 400) {
              this.showAlert(error);
            }
            throw error;
          }
        )
      );
  }

  /**
   * ログアウト処理
   *
   * ストレージからトークンを削除し、「ログイン状態」をfalseに
   * 設定し、ログイン画面に遷移
   */
  async logout() {
    await this.storage.remove(SystemConst.ROLE_KEY);
    this.storage.remove(SystemConst.TOKEN_KEY).then(() => {
      this.authenticationState.next(false);
      this.router.navigate(['login']);
    });
  }

  /**
   * ログイン状態を返却
   *
   * @return { boolean } ログイン済みはTrue, 未ログインはFalse
   */
  isAuthenticated() {
    console.log("ログ：isAuthenticated：", this.authenticationState.value);
    return this.authenticationState.value;
  }

  /**
   * アラートを表示
   *
   * @param error エラー
   */
  async showAlert(error: any) {
    console.log('ログ：エラー:', JSON.stringify(error));
    var options: any;

    if (error.error instanceof ErrorEvent) {

      // クライアントエラー（のはずだけど、400系もこっちには入らない）
      options = {
        header: 'エラー',
        message: error.error.message,
        buttons: ['OK'],
        backdropDismiss: false,
        cssClass: 'alertCustomCss',
      };

    } else if (error.status === 404) {

      // エラーがAPIバージョンエラーで、apiバージョンが異なるときはリロード
      if (error.error['returnCode'] == SystemConst.ReturnCode.VERSION_ERROR) {

        if (error.error['apiVersion'] == SystemConst.API_VERSION) {

          // APIバージョンは同じだけどURlが間違っているエラー
          options = {
            header: 'エラー[URL]',
            message: 'ブラウザをを更新してください',
            buttons: ['OK'],
            backdropDismiss: false,
            cssClass: 'alertCustomCss',
          };

        } else {

          // APIバージョンもURlも間違っているエラー
          options = {
            header: 'エラー[API]',
            message: 'ブラウザを更新してください',
            buttons: ['OK'],
            backdropDismiss: false,
            cssClass: 'alertCustomCss',
          };
        }

      } else {
        // Not Found
        options = {
          header: 'エラー',
          message: '見つかりませんでした',
          buttons: ['OK'],
          backdropDismiss: false,
          cssClass: 'alertCustomCss',
        };
      }

    } else {

      // サーバーエラー
      options = {
        header: 'エラー',
        message: 'システムエラーです',
        buttons: ['OK'],
        backdropDismiss: false,
        cssClass: 'alertCustomCss',
      };
    }

    const errorAlert = await this.alertController.create(options);
    await errorAlert.present();
  }

}
