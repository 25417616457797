import { FormControl } from '@angular/forms';

export class CustomValidator {

    /**
     * 整数チェック
     * ※0以上(符号、少数は許容しない)
     *
     * @param formControl フォームコントロール
     * @return trueの場合整数ではない、falseの場合整数である、もしくは値なし
     */
    public static integer(formControl: FormControl) {
        let isInteger = false;
        const value = String(formControl.value); // 値は文字列として扱う

        if (value !== null && value !== '' && value !== 'null') {
            isInteger = !/^([1-9]\d*|0)$/.test(value);
        }

        return isInteger ? { 'integer': true } : null;
    }

    /**
     * カタカナチェック
     * 
     * @param formControl フォームコントロール
     * @return trueの場合カタカナ以外が含まれる、falseの場合カタカナのみ、もしくは値なし
     */
    public static katakana(formControl: FormControl) {
        let isKatakana = false;
        const value = String(formControl.value);

        if (value !== null && value !== '' && value !== 'null'){
            isKatakana = !/^[\u30A1-\u30FF\uFF66-\uFF9F]*$/.test(value);
        }

        return isKatakana ? { 'katakana': true } : null;
    }
}
