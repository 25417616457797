import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ModalSelectCheckitemPage } from './modal-select-checkitem.page';

const routes: Routes = [
  {
    path: '',
    component: ModalSelectCheckitemPage
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ModalSelectCheckitemPageRoutingModule {}
