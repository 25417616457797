import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';

import { SystemConst } from '../app-constant';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  /**
   * Constructor
   *
   * @param { Router } router
   * @param { AlertController } alertController
   * @param { AuthService } authService
   */
  constructor(
    private router: Router,
    private alertController: AlertController,
    private authService: AuthService
  ) { }

  /**
   * アラートを表示
   *
   * @param error エラー
   */
  async showAlert(error: any) {
    console.log("ログ：エラー:", JSON.stringify(error))
    var options: any;

    if (error.error instanceof ErrorEvent) {

      // クライアントエラー（のはずだけど、400系もこっちには入らない）
      options = {
        header: 'エラー',
        message: error.error.message,
        buttons: ['OK'],
        backdropDismiss: false,
        cssClass: 'alertCustomCss',
      };

    } else if (error.status === 400) {

      let msg = JSON.stringify(error.error);
      msg = msg.slice(1);             // 文頭の { を削除
      msg = msg.slice(0, -1);         // 文末の } を削除
      msg = msg.replace(',', '<br>'); // カンマがあったら改行に置換

      // Bad Request
      options = {
        header: 'リクエストが不正です',
        message: msg,
        buttons: ['OK'],
        backdropDismiss: false,
        cssClass: 'alertCustomCss',
      };

    } else if (error.status === 401) {

      // Unauthorized
      options = {
        header: '認証に失敗しました',
        message: 'ログインの認証が切れました。<br>再度ログインしてください。',
        buttons: [
          {
            text: 'OK',
            handler: () => {
              this.authService.logout();
            }
          }
        ],
        backdropDismiss: false,
        cssClass: 'alertCustomCss',
      };

    } else if (error.status === 404) {

      // エラーがAPIバージョンエラーで、apiバージョンが異なるときはリロード
      if (error.error['returnCode'] == SystemConst.ReturnCode.VERSION_ERROR) {

        if (error.error['apiVersion'] == SystemConst.API_VERSION) {

          // APIバージョンは同じだけどURlが間違っているエラー
          options = {
            header: 'エラー[URL]',
            message: 'サーバーのシステムが更新されました<br>恐れ入りますが、最初からやり直してください',
            buttons: [
              {
                text: 'OK',
                handler: () => {
                  this.router.navigate(['login']);
                }
              }
            ],
            backdropDismiss: false,
            cssClass: 'alertCustomCss',
          };

        } else {

          // APIバージョンエラー
          options = {
            header: 'エラー[API]',
            message: 'サーバーのシステムが更新されました<br>恐れ入りますが、最初からやり直してください',
            buttons: [
              {
                text: 'OK',
                handler: () => {
                  this.router.navigate(['login']);
                }
              }
            ],
            backdropDismiss: false,
            cssClass: 'alertCustomCss',
          };
        }

      } else {

        // Not Found
        options = {
          header: 'エラー[404]',
          message: '見つかりませんでした',
          buttons: ['OK'],
          backdropDismiss: false,
          cssClass: 'alertCustomCss',
        };
      }

    } else {

      // サーバーエラー
      options = {
        header: 'エラー',
        message: 'システムエラーです',
        buttons: ['OK'],
        backdropDismiss: false,
        cssClass: 'alertCustomCss',
      };
    }

    const errorAlert = await this.alertController.create(options);
    await errorAlert.present();
  }
}
