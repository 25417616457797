import { formatDate } from '@angular/common';
import { Component, ViewChild, OnInit, Inject, LOCALE_ID } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CalendarComponent } from 'ionic2-calendar/calendar';

@Component({
  selector: 'app-modal-calendar',
  templateUrl: './modal-calendar.page.html',
  styleUrls: ['./modal-calendar.page.scss'],
})
export class ModalCalendarPage implements OnInit {

  /* カレンダー制御用 */
  viewTitle: string;
  calendar = {
    formatMonthTitle: 'yyyy年 MMMM',
    calendarMode: 'month',
    showEventDetail: false,
    currentDate: new Date(),
    autoSelect: false,
    locale: this.locale
  };

  // 月を変更した際にONにして、onCurrentDateChangedでOFFにする
  // 意図的に選択した日だけを取得したいけど、月を変更すると自動で同じ日が選択される
  changeMonth = true;

  @ViewChild(CalendarComponent, { static: false }) myCalendar: CalendarComponent;

  /**
   * Constructor
   *
   * @param { ModalController } modalController
   *
   */
  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private modalController: ModalController,
  ) { }

  ngOnInit() { }

  /**
   * 次の月を表示
   *
   */
  next() {
    this.myCalendar.slideNext();
    this.changeMonth = true;
  }

  /**
   * 前の月を表示
   *
   */
  back() {
    this.myCalendar.slidePrev();
    this.changeMonth = true;
  }

  /**
   * タイトルを設定
   *
   */
  onViewTitleChanged(title) {
    this.viewTitle = title;
  }

  /**
   * 過去日は選択不可
   *
   */
  markDisabled(date: Date) {
    var current = new Date();
    current = new Date(current.getFullYear(), current.getMonth(), current.getDate());
    date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    return date < current;
  };

  /**
   * 日付が選択された場合
   *
   */
  onCurrentDateChanged(date: Date) {
    if (this.changeMonth) {
      this.changeMonth = false;
    } else {
      let selected = formatDate(date, 'yyyy-MM-dd', this.locale);
      this.modalController.dismiss({ work_date: selected });
    }
  };

  /**
   * 閉じるボタンが押された場合
   *
   */
  close() {
    this.modalController.dismiss();
  }
}
