import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';

import { FileUploader } from 'ng2-file-upload';
import { SystemConst } from '../../../app-constant';

@Component({
  selector: 'app-modal-image-upload',
  templateUrl: './modal-image-upload.page.html',
  styleUrls: ['./modal-image-upload.page.scss'],
})
export class ModalImageUploadPage {

  uploader: FileUploader = new FileUploader({});
  hasBaseDropZoneOver: boolean = false;
  previewPath: any;
  comment: string = null;
  imageData: string | ArrayBuffer = null;

  /**
   * Constructor
   *
   * @param { DomSanitizer } sanitizer
   * @param { ModalController } modalController
   *
   */
  constructor(
    private sanitizer: DomSanitizer,
    private modalController: ModalController,
  ) {
    this.uploader.onAfterAddingFile = (fileItem) => {
      if (this.uploader.queue.length > 1) {
        this.uploader.queue.splice(0, 1);
      }
      this.previewPath = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(fileItem._file)));
    }
  }

  /**
   * ファイルをドロップされたときの処理
   *
   * @param { any } event イベント
   */
  fileOverBase(event): void {
    this.hasBaseDropZoneOver = event;
  }

  /**
   * ファイルをアップロードする
   *
   */
  uploadFiles() {
    let fileCount: number = this.uploader.queue.length;
    if (fileCount > 0) {
      this.uploader.queue.forEach((val, i, array) => {
        let fileReader = new FileReader();
        fileReader.onloadend = (e) => {
          this.imageData = fileReader.result;

          // 追加する画像（リサイズ後）
          let resized_image: string;
          let resized_width: number;
          let resized_height: number;

          // 画像データの縦横サイズを取得する
          let element = document.getElementById('reportImage') as HTMLImageElement;
          let width = element.naturalWidth;
          let height = element.naturalHeight;

          // 縦か横が最大幅を超える時
          if (width > SystemConst.MAX_WIDTH || height > SystemConst.MAX_WIDTH) {

            // 長い方を最大幅にする
            if (width < height) {
              resized_height = SystemConst.MAX_WIDTH;
              resized_width = width * SystemConst.MAX_WIDTH / height;
            } else {
              resized_width = SystemConst.MAX_WIDTH;
              resized_height = height * SystemConst.MAX_WIDTH / width;
            }

            let canvas = document.createElement("canvas")
            let ctx = canvas.getContext('2d')
            let tmpImage = new Image()

            canvas.width = 0
            canvas.height = 0

            tmpImage.src = this.imageData.toString();
            tmpImage.onload = () => {
              let w = resized_width
              let h = resized_height
              canvas.width = w
              canvas.height = h
              ctx.drawImage(tmpImage, 0, 0, w, h)
              resized_image = canvas.toDataURL("image/jpeg", 0.9);
              this.modalController.dismiss({ imageData: resized_image, comment: this.comment });
            }
          } else {
            // 小さい写真はそのまま
            this.modalController.dismiss({ imageData: this.imageData, comment: this.comment });
          }
        }
        fileReader.readAsDataURL(val._file);
      });
    }
  }

  /**
   * 画面を閉じる
   *
   */
  close() {
    this.modalController.dismiss();
  }
}
