import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { ModalCallcenterPageRoutingModule } from './modal-callcenter-routing.module';

import { ModalCallcenterPage } from './modal-callcenter.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    ModalCallcenterPageRoutingModule
  ],
  declarations: [ModalCallcenterPage]
})
export class ModalCallcenterPageModule { }
