import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { EDIT_CHECKLIST_POST, EDIT_REPORT_POST, ADD_PHOTO_POST, EDIT_COMMENT_POST, EDIT_ORDER_POST } from '../interfaces/report';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  /* 通信先 */
  url = environment.apiUrl;

  /**
   * Constructor
   *
   * @param { HttpClient } httpClient
   * @param { AlertService } alertService
   */
  constructor(
    private httpClient: HttpClient,
    private alertService: AlertService
  ) { }

  /**
   * サーバーからチェックリストテンプレート一覧を取得
   *
   * ソート：order
   * 検索：name
   * 戻り：TEMPLATE_RESPONSE
   * 対象：WEB ○ / APP ×
   *
   * @param { number } p            ページ番号(1〜)
   * @param { number } s            1ページあたりの取得数
   * @param { string } ordering     表示順
   * @param { string } search       検索文字列
   * @returns { Observable<any> }   結果
   * @throws { any }                エラーメッセージ
   */
  getTemplateList(p?: number, s?: number, ordering?: string, search?: string): Observable<any> {

    let params = new HttpParams();

    // ページ番号(1〜)
    if (p) {
      params = params.set('p', p.toString());
    }

    // 1ページあたりの取得数
    if (s) {
      params = params.set('s', s.toString());
    }

    // 並び替えカラム名
    if (ordering) {
      params = params.set('ordering', ordering);
    }

    // 検索条件
    if (search) {
      params = params.set('search', search);
    }

    return this.httpClient.get(`${this.url}/report/templates`, { params })
      .pipe(
        catchError(
          error => {
            this.alertService.showAlert(error);
            throw error;
          }
        )
      );
  }

  /**
   * サーバーからチェックリストチェック項目一覧を取得
   *
   * ソート：order
   * 検索：details
   * 戻り：ITEM_RESPONSE
   * 対象：WEB ○ / APP ×
   *
   * @param { number } p            ページ番号(1〜)
   * @param { number } s            1ページあたりの取得数
   * @param { string } ordering     表示順
   * @param { string } search       検索文字列
   * @returns { Observable<any> }   結果
   * @throws { any }                エラーメッセージ
   */
  getCheckItemList(p?: number, s?: number, ordering?: string, search?: string): Observable<any> {

    let params = new HttpParams();

    // ページ番号(1〜)
    if (p) {
      params = params.set('p', p.toString());
    }

    // 1ページあたりの取得数
    if (s) {
      params = params.set('s', s.toString());
    }

    // 並び替えカラム名
    if (ordering) {
      params = params.set('ordering', ordering);
    }

    // 検索条件
    if (search) {
      params = params.set('search', search);
    }

    return this.httpClient.get(`${this.url}/report/checkitems`, { params })
      .pipe(
        catchError(
          error => {
            this.alertService.showAlert(error);
            throw error;
          }
        )
      );
  }

  /**
   * チェックリストのチェック内容を変更
   *
   * 戻り：COMMON_RESPONSE
   * 対象：WEB ○ / APP ○
   *
   * @param { number } workID                 作業順序
   * @param { number } chkOrder               チェックリスト順序
   * @param { EDIT_CHECKLIST_POST } postdata  ポストデータ
   * @returns { Observable<any> }             結果
   * @throws { any }                          エラーメッセージ
   */
  editChecklist(workID: number, chkOrder: number, postdata: EDIT_CHECKLIST_POST): Observable<any> {
    return this.httpClient.post<any>(`${this.url}/report/${workID}/${chkOrder}/edit`, postdata)
      .pipe(
        catchError(
          error => {
            this.alertService.showAlert(error);
            throw error;
          }
        )
      );
  }

  /**
   * 報告書を取得
   *
   * 戻り：REPORT
   * 対象：WEB ○ / APP ○
   *
   * @param { number } workID           作業ID
   * @returns { Observable<any> }       結果
   * @throws { any }                    エラーメッセージ
   */
  getReportDetail(workID: number): Observable<any> {
    return this.httpClient.get<any>(`${this.url}/report/${workID}`)
      .pipe(
        catchError(
          error => {
            this.alertService.showAlert(error);
            throw error;
          }
        )
      );
  }

  /**
   * 報告書を変更
   *
   * 戻り：COMMON_RESPONSE
   * 対象：WEB ○ / APP ×
   *
   * @param { number } workID                 作業順序
   * @param { EDIT_REPORT_POST } postdata     ポストデータ
   * @returns { Observable<any> }             結果
   * @throws { any }                          エラーメッセージ
   */
  editReport(workID: number, postdata: EDIT_REPORT_POST): Observable<any> {
    return this.httpClient.post<any>(`${this.url}/report/${workID}/edit`, postdata)
      .pipe(
        catchError(
          error => {
            this.alertService.showAlert(error);
            throw error;
          }
        )
      );
  }

  /**
   * 報告書PDFを取得
   *
   * 戻り：PDFファイル形式
   * 対象：WEB ○ / APP ○
   *
   * @param { number } workID                 作業順序
   * @returns { Observable<any> }             結果
   * @throws { any }                          エラーメッセージ
   */
  getReportPDF(workID: number): Observable<any> {
    return this.httpClient.get(`${this.url}/report/${workID}/report.pdf`, { responseType: 'arraybuffer' })
      .pipe(
        catchError(
          error => {
            this.alertService.showAlert(error);
            throw error;
          }
        )
      );
  }

  /**
   * 作業開始・終了時刻取得
   *
   * 戻り：WORK_DATES_RESPONSE
   * 対象：WEB ○ / APP ○
   *
   * @param { number } workID                 作業順序
   * @returns { Observable<any> }             結果
   * @throws { any }                          エラーメッセージ
   */
  getWorkDates(workID: number): Observable<any> {
    return this.httpClient.get<any>(`${this.url}/report/${workID}/dates`)
      .pipe(
        catchError(
          error => {
            this.alertService.showAlert(error);
            throw error;
          }
        )
      );
  }

  /**
   * 報告書写真取得(base64文字列)
   *
   * 戻り：REPORT_PHOTO_RESPONSE
   * 対象：WEB ○ / APP ○
   *
   * @param { number } workID                 作業順序
   * @param { number } chkOrder               チェックリスト順序
   * @param { number } phoOrder               写真順序
   * @returns { Observable<any> }             結果
   * @throws { any }                          エラーメッセージ
   */
  getPhoto(workID: number, chkOrder: number, phoOrder: number): Observable<any> {
    return this.httpClient.get<any>(`${this.url}/report/${workID}/${chkOrder}/photo/${phoOrder}`)
      .pipe(
        catchError(
          error => {
            this.alertService.showAlert(error);
            throw error;
          }
        )
      );
  }

  /**
   * 報告書写真取得(画像))
   *
   * 戻り：画像ファイル形式
   * 対象：WEB ○ / APP ○
   *
   * @param { number } workID                作業順序
   * @param { number } chkOrder              チェックリスト順序
   * @param { number } phoOrder              写真順序
   * @param { string } imageType             写真の種類
   * @returns { Observable<any> }            結果
   * @throws { any }                         エラーメッセージ
   */
  getPhotoImage(workID: number, chkOrder: number, phoOrder: number, imageType: string): Observable<any> {
    return this.httpClient.get<any>(`${this.url}/report/${workID}/${chkOrder}/photo/${phoOrder}.${imageType}`)
      .pipe(
        catchError(
          error => {
            this.alertService.showAlert(error);
            throw error;
          }
        )
      );
  }

  /**
   * チェックリストの写真追加
   *
   * 戻り：COMMON_RESPONSE
   * 対象：WEB ○ / APP ○
   *
   * @param { number } workID                 作業順序
   * @param { number } chkOrder               チェックリスト順序
   * @param { ADD_PHOTO_POST } postdata       ポストデータ
   * @returns { Observable<any> }             結果
   * @throws { any }                          エラーメッセージ
   */
  addPhoto(workID: number, chkOrder: number, postdata: ADD_PHOTO_POST): Observable<any> {
    return this.httpClient.post<any>(`${this.url}/report/${workID}/${chkOrder}/photo/add`, postdata)
      .pipe(
        catchError(
          error => {
            this.alertService.showAlert(error);
            throw error;
          }
        )
      );
  }

  /**
   * チェックリストの写真コメント修正
   *
   * 戻り：COMMON_RESPONSE
   * 対象：WEB ○ / APP ○
   *
   * @param { number } workID                 作業順序
   * @param { number } chkOrder               チェックリスト順序
   * @param { number } phoOrder               写真順序
   * @param { EDIT_COMMENT_POST } postdata    ポストデータ
   * @returns { Observable<any> }             結果
   * @throws { any }                          エラーメッセージ
   */
  editPhotoComment(workID: number, chkOrder: number, phoOrder: number, postdata: EDIT_COMMENT_POST): Observable<any> {
    return this.httpClient.post<any>(`${this.url}/report/${workID}/${chkOrder}/photo/${phoOrder}/edit`, postdata)
      .pipe(
        catchError(
          error => {
            this.alertService.showAlert(error);
            throw error;
          }
        )
      );
  }

  /**
   * チェックリストの写真順修正
   *
   * 戻り：COMMON_RESPONSE
   * 対象：WEB ○ / APP ○
   *
   * @param { number } workID                 作業順序
   * @param { number } chkOrder               チェックリスト順序
   * @param { EDIT_ORDER_POST } postdata      ポストデータ
   * @returns { Observable<any> }             結果
   * @throws { any }                          エラーメッセージ
   */
  editPhotoOrder(workID: number, chkOrder: number, postdata: EDIT_ORDER_POST): Observable<any> {
    return this.httpClient.post<any>(`${this.url}/report/${workID}/${chkOrder}/photo/order`, postdata)
      .pipe(
        catchError(
          error => {
            this.alertService.showAlert(error);
            throw error;
          }
        )
      );
  }

  /**
   * チェックリストの写真削除
   *
   * 戻り：COMMON_RESPONSE
   * 対象：WEB ○ / APP ×
   *
   * @param { number } workID                 作業順序
   * @param { number } chkOrder               チェックリスト順序
   * @param { number } phoOrder               写真順序
   * @returns { Observable<any> }             結果
   * @throws { any }                          エラーメッセージ
   */
  deletePhoto(workID: number, chkOrder: number, phoOrder: number): Observable<any> {
    return this.httpClient.post<any>(`${this.url}/report/${workID}/${chkOrder}/photo/${phoOrder}/delete`, null)
      .pipe(
        catchError(
          error => {
            this.alertService.showAlert(error);
            throw error;
          }
        )
      );
  }
}
