import { Injectable } from '@angular/core';
import { MapsAPILoader } from '@agm/core';

declare let google: any;

@Injectable({
  providedIn: 'root'
})
export class MapService {

  /* ジオコーダー */
  private geocoder: any = null;

  /**
   * Constructor
   *
   * @param { MapsAPILoader } mapsAPILoader
   */
  constructor(
    private mapsAPILoader: MapsAPILoader,
  ) { }

  /**
   * 住所から緯度経度を取得
   *
   * @param { string } address    住所
   * @returns { Promise<any> }    緯度経度に変換した結果
   */
  geocoding(address: string): Promise<any> {

    return this.mapsAPILoader.load().then(() => {
      this.geocoder = new google.maps.Geocoder();

      return new Promise((resolve, reject) => {
        this.geocoder.geocode({ 'address': address }, (result: any, status: any) => {
          if (status === google.maps.GeocoderStatus.OK) {
            resolve(result);
          } else {
            reject(status);
          }
        });
      });
    });
  }

  /**
   * 緯度経度から住所を取得
   *
   * @param { number } latitude    緯度
   * @param { number } longitude   経度
   * @returns { Promise<any> }     住所
   */
  getAddress(latitude: number, longitude: number): Promise<any> {

    return this.mapsAPILoader.load().then(() => {
      this.geocoder = new google.maps.Geocoder();
      var latlng = new google.maps.LatLng(latitude, longitude);

      return new Promise((resolve, reject) => {
        this.geocoder.geocode({ 'location': latlng }, (results: any, status: any) => {
          if (status === google.maps.GeocoderStatus.OK) {
            if (results[0].geometry) {

              // 住所を取得(日本の場合だけ「日本, 」を削除)
              var address = results[0].formatted_address.replace('日本、', '');
              resolve(address);
            }
          } else {
            resolve("取得失敗");
          }
        });
      });
    });
  }
}
