import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { NEW_CALLCENTER_POST, EDIT_CALLCENTER_POST } from '../interfaces/callcenter';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root'
})
export class CallcenterService {

  /* 通信先 */
  url = environment.apiUrl;

  /**
   * Constructor
   *
   * @param { HttpClient } httpClient
   * @param { AlertService } alertService
   */
  constructor(
    private httpClient: HttpClient,
    private alertService: AlertService
  ) { }

  /**
   * サーバーからコールセンター一覧を取得
   *
   * ソート：id, email、name、 name_kana
   * 検索：email、name、 name_kana
   * 戻り：CALLCENTER_RESPONSE
   * 対象：WEB ○ / APP ×
   *
   * @param { number } p            ページ番号(1〜)
   * @param { number } s            1ページあたりの取得数
   * @param { string } ordering     表示順
   * @param { string } search       検索文字列
   * @returns { Observable<any> }   結果
   * @throws { any }                エラーメッセージ
   */
  getCallCenterList(p?: number, s?: number, ordering?: string, search?: string): Observable<any> {

    var params = new HttpParams();

    // ページ番号(1〜)
    if (p) {
      params = params.set('p', p.toString());
    }

    // 1ページあたりの取得数
    if (s) {
      params = params.set('s', s.toString());
    }

    // 並び替えカラム名
    if (ordering) {
      params = params.set('ordering', ordering);
    }

    // 検索条件
    if (search) {
      params = params.set('search', search);
    }

    return this.httpClient.get(`${this.url}/user/cc`, { params })
      .pipe(
        catchError(
          error => {
            this.alertService.showAlert(error);
            throw error;
          }
        )
      );
  }

  /**
   * コールセンター情報詳細を取得
   *
   * 戻り：CALLCENTER
   * 対象：WEB ○ / APP ×
   *
   * @param { number } callcenterID     コールセンターID
   * @returns { Observable<any> }       結果
   * @throws { any }                    エラーメッセージ
   */
  getCallCenterDetail(callcenterID: number): Observable<any> {
    return this.httpClient.get<any>(`${this.url}/user/cc/${callcenterID}`)
      .pipe(
        catchError(
          error => {
            this.alertService.showAlert(error);
            throw error;
          }
        )
      );
  }

  /**
   * コールセンターを新規作成する
   *
   * 戻り：NEW_CALLCENTER_RESPONSE
   * 対象：WEB ○ / APP ×
   *
   * @param { NEW_CALLCENTER_POST } postdata  ポストデータ
   * @returns { Observable<any> }             結果
   * @throws { any }                          エラーメッセージ
   */
  newCallCenter(postdata: NEW_CALLCENTER_POST): Observable<any> {
    return this.httpClient.post<any>(`${this.url}/user/cc/new`, postdata)
      .pipe(
        catchError(
          error => {
            this.alertService.showAlert(error);
            throw error;
          }
        )
      );
  }

  /**
   * コールセンターを編集する
   *
   * 戻り：COMMON_RESPONSE
   * 対象：WEB ○ / APP ×
   *
   * @param { number } callcenterID             コールセンターID
   * @param { EDIT_CALLCENTER_POST } postdata   ポストデータ
   * @returns { Observable<any> }               結果
   * @throws { any }                            エラーメッセージ
   */
  editCallCenter(callcenterID: number, postdata: EDIT_CALLCENTER_POST): Observable<any> {
    return this.httpClient.post<any>(`${this.url}/user/cc/${callcenterID}/edit`, postdata)
      .pipe(
        catchError(
          error => {
            this.alertService.showAlert(error);
            throw error;
          }
        )
      );
  }

  /**
   * コールセンターを削除する
   *
   * 戻り：COMMON_RESPONSE
   * 対象：WEB ○ / APP ×
   *
   * @param { number } callcenterID     コールセンターID
   * @returns { Observable<any> }       結果
   * @throws { any }                    エラーメッセージ
   */
  deleteCallCenter(callcenterID: number): Observable<any> {
    return this.httpClient.post<any>(`${this.url}/user/cc/${callcenterID}/delete`, null)
      .pipe(
        catchError(
          error => {
            this.alertService.showAlert(error);
            throw error;
          }
        )
      );
  }
}