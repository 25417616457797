import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';

import { SystemConst } from '../../../app-constant';
import { COMMON_RESPONSE } from '../../../interfaces/common';
import { EDIT_SUPPORT_POST } from '../../../interfaces/task';
import { SupportService } from '../../../providers/support.service';

@Component({
  selector: 'app-modal-edit-support',
  templateUrl: './modal-edit-support.page.html',
  styleUrls: ['./modal-edit-support.page.scss'],
})
export class ModalEditSupportPage implements OnInit {

  /* パラメータ */
  @Input() supportID: number;             // 依頼ID
  @Input() subject: string;               // 件名
  @Input() details: string;               // 詳細
  @Input() request_customer_id: number;   // 依頼者ID

  /* 画面制御 */
  supportForm: FormGroup;                 // 依頼フォーム
  isSubmitted: boolean = false;           // サブミット済み

  /**
   * Constructor
   *
   * @param { FormBuilder } formBuilder
   * @param { AlertController } alertController
   * @param { LoadingController } loadingController
   * @param { ModalController } modalController
   * @param { SupportService } supportService
   */
  constructor(
    private formBuilder: FormBuilder,
    private alertController: AlertController,
    private loadingController: LoadingController,
    private modalController: ModalController,
    private supportService: SupportService,
  ) { }

  /**
   * ngOnInit
   * フォームの設定
   *
   */
  ngOnInit() {

    // フォーム
    this.supportForm = this.formBuilder.group({
      subject: [this.subject, Validators.compose([Validators.maxLength(100), Validators.required])],
      details: [this.details, Validators.compose([Validators.maxLength(4000), Validators.required])],
      request_customer_id: [this.request_customer_id, Validators.required]
    });
  }

  /**
   * エラーフィールドを返却
   *
   */
  get errorControl() {
    return this.supportForm.controls;
  }

  /**
   * 登録ボタンを押された時の処理
   *
   * 入力エラーがある場合はそのままreturn
   * アラートを表示後、新規登録・編集をID有無で判別して、
   * 該当の機能を呼び出し
   *
   */
  async submitForm() {

    this.isSubmitted = true;

    // エラーがある時は何もしない
    if (!this.supportForm.valid) {
      return false;
    }

    // アラート表示
    const alert = await this.alertController.create({
      header: '編集',
      message: '依頼の情報を変更します',
      buttons: [
        {
          text: 'キャンセル',
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: 'Ok',
          handler: () => {
            this.editSupport();
          }
        }
      ]
    });
    await alert.present();
  }

  /**
   * 依頼を変更する
   *
   */
  async editSupport() {

    const tmp: EDIT_SUPPORT_POST = {
      subject: this.supportForm.value.subject,
      details: this.supportForm.value.details,
      request_customer_id: this.request_customer_id
    }

    // くるくる
    let loading = await this.loadingController.create();
    await loading.present();

    this.supportService.editSupport(this.supportID, tmp).subscribe(
      res => {
        const response = res as COMMON_RESPONSE;
        loading.dismiss();
        if (response.returnCode === SystemConst.ReturnCode.SUCCESS) {
          this.modalController.dismiss({ supportID: this.supportID });
        }
      },
      error => {
        loading.dismiss();
        console.log("ログ：error：editSupport", error);
      }
    );
  }

  /**
   * 画面を閉じる
   *
   */
  close() {
    this.modalController.dismiss();
  }
}
