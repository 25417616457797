import { Component, Input } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { SUPPORT, SUPPORT_RESPONSE } from '../../../interfaces/task';
import { SupportService } from '../../../providers/support.service';

@Component({
  selector: 'app-modal-select-support',
  templateUrl: './modal-select-support.page.html',
  styleUrls: ['./modal-select-support.page.scss'],
})
export class ModalSelectSupportPage {

  /* パラメータ */
  @Input() customerID: number;

  /* 画面制御用 */
  allSupports: SUPPORT[] = [];  // 全検索結果
  supports: SUPPORT[] = [];     // フィルター後の検索結果
  select: SUPPORT;              // クリックされた依頼
  searchKey: string = "";       // 検索ワード
  show: number = 100000;        // 表示件数（全件取得したいので暫定10万件）
  page: number = 1;             // 何ページ目

  /**
   * Constructor
   *
   * @param { LoadingController } loadingController
   * @param { ModalController } modalController
   * @param { SupportService } supportService
   *
   */
  constructor(
    private loadingController: LoadingController,
    private modalController: ModalController,
    private supportService: SupportService,
  ) { }

  /**
   * 画面表示時の処理
   *
   */
  async ionViewWillEnter() {
    await this.loadData();
  }

  /**
   * サーバーからデータを取得し、画面制御用の変数に値を設定する
   *
   */
  async loadData() {

    // API設定
    const search = (this.searchKey !== "" ? this.searchKey : null);

    // くるくる
    let loading = await this.loadingController.create();
    await loading.present();

    // サーバーに問い合わせ
    this.supportService.getSupportList(this.page, this.show, null, search).subscribe(
      res => {
        const response = res as SUPPORT_RESPONSE;
        this.allSupports = response.results;
        this.customerFilter();
        loading.dismiss();
      },
      error => {
        loading.dismiss();
        console.log("ログ：error：getSupportList", error);
      }
    );
  }

  /**
   * 依頼者でフィルターにかける
   *
   */
  customerFilter() {

    if (!this.customerID) {
      // 依頼者IDが指定されていない時は何もしない
      this.supports = this.allSupports;
      return;
    }

    this.supports = this.allSupports.filter(sup => {
      return sup.request_customer.id == this.customerID;
    });
  }

  /**
   * クリックされた依頼の情報を表示用変数に格納
   *
   * @param { SUPPORT } target 選択された依頼
   *
   */
  showDetail(target: SUPPORT) {
    this.select = target;
  }

  /**
   * 選択された依頼の情報を戻り値にセットして、画面を閉じる
   *
   */
  setSupport() {
    this.modalController.dismiss({ support: this.select });
  }

  /**
   * 画面を閉じる
   *
   */
  close() {
    this.modalController.dismiss();
  }
}
