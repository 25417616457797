import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ModalPreviewPageRoutingModule } from './modal-preview-routing.module';

import { ModalPreviewPage } from './modal-preview.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ModalPreviewPageRoutingModule
  ],
  declarations: [ModalPreviewPage]
})
export class ModalPreviewPageModule {}
