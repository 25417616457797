import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ModalEditSupportPageRoutingModule } from './modal-edit-support-routing.module';

import { ModalEditSupportPage } from './modal-edit-support.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    ModalEditSupportPageRoutingModule
  ],
  declarations: [ModalEditSupportPage]
})
export class ModalEditSupportPageModule { }
