import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { ModalWorkerPageRoutingModule } from './modal-worker-routing.module';

import { ModalWorkerPage } from './modal-worker.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    ModalWorkerPageRoutingModule
  ],
  declarations: [ModalWorkerPage]
})
export class ModalWorkerPageModule { }
