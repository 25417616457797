import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Storage } from '@ionic/storage';

import { SystemConst } from './app-constant'
import { CommonService } from './providers/common.service';
import { AuthService } from './providers/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  /* アイコン */
  icon: string = '../../../assets/images/icon.png';

  /* ユーザー権限 */
  roles: string[] = null;

  /**
   * Constructor
   *
   * @param { Platform } platform
   * @param { SplashScreen } splashScreen
   * @param { StatusBar } statusBar
   * @param { Storage } storage
   * @param { AuthService } authService
   * @param { CommonService } commonService
   */
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private storage: Storage,
    private authService: AuthService,
    private commonService: CommonService
  ) {
    this.initializeApp();
  }

  /**
   * 初期化処理
   *
   */
  initializeApp() {

    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();

      // ログインを検知したら、ユーザー権限を取得
      this.authService.authenticationState.subscribe(
        state => {
          if (state) {
            // ログイン検知
            if (!this.roles) {
              this.commonService.getCurrentUser().subscribe(
                data => {
                  if (data) {
                    this.roles = data.role;
                    this.storage.set(SystemConst.ROLE_KEY, this.roles);
                  }
                });
            }
          } else {
            // ログアウト検知
            this.roles = null;
          }
        });
    });
  }

  /**
   * ログアウト処理
   *
   */
  logout() {
    this.authService.logout();
  }
}
