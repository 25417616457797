import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController, LoadingController, ModalController, ToastController } from '@ionic/angular';

import { SystemConst } from '../../../app-constant';
import { COMMON_RESPONSE } from '../../../interfaces/common';
import { NEW_CALLCENTER_RESPONSE, EDIT_CALLCENTER_POST } from '../../../interfaces/callcenter';
import { CallcenterService } from '../../../providers/callcenter.service';
import { CustomValidator } from '../../../validators/custom-validator';

@Component({
  selector: 'app-modal-callcenter',
  templateUrl: './modal-callcenter.page.html',
  styleUrls: ['./modal-callcenter.page.scss'],
})
export class ModalCallcenterPage implements OnInit {

  /* パラメータ */
  @Input() callcenterID: number;  // コールセンターID
  @Input() cc_name: string;       // コールセンター名
  @Input() cc_name_kana: string;  // コールセンター名カナ
  @Input() email: string;         // 電子メール
  @Input() is_manager: boolean;   // 管理者フラグ

  /* 画面制御 */
  password: string;               // パスワード
  newForm: FormGroup;
  editForm: FormGroup;
  isSubmitted: boolean = false;

  /**
   * Constructor
   *
   * @param { FormBuilder } formBuilder
   * @param { AlertController } alertController
   * @param { LoadingController } loadingController
   * @param { ModalController } modalController
   * @param { ToastController } toastController
   * @param { CustomerService } customerService
   */
  constructor(
    private formBuilder: FormBuilder,
    private alertController: AlertController,
    private loadingController: LoadingController,
    private modalController: ModalController,
    private toastController: ToastController,
    private callcenterService: CallcenterService
  ) { }

  /**
   * ngOnInit
   * フォームの設定
   *
   */
  ngOnInit() {

    // 新規登録時のフォーム
    this.newForm = this.formBuilder.group({
      cc_name: [this.cc_name, Validators.compose([Validators.maxLength(200), Validators.required])],
      cc_name_kana: [this.cc_name_kana, Validators.compose([Validators.maxLength(200), Validators.required, CustomValidator.katakana])],
      password: [this.password, Validators.compose([Validators.minLength(8), Validators.maxLength(128), Validators.required])],
      email: [this.email, Validators.compose([Validators.email, Validators.required])],
      is_manager: [this.is_manager,]
    });

    // 編集時のフォーム
    this.editForm = this.formBuilder.group({
      cc_name: [this.cc_name, Validators.compose([Validators.maxLength(200), Validators.required])],
      cc_name_kana: [this.cc_name_kana, Validators.compose([Validators.maxLength(200), Validators.required, CustomValidator.katakana])],
      password: [this.password, Validators.compose([Validators.minLength(8), Validators.maxLength(128)])],
      email: [this.email, Validators.compose([Validators.email, Validators.required])],
      is_manager: [this.is_manager,]
    });
  }

  /**
   * エラーフィールドを返却
   *
   */
  get errorControl() {
    if (this.callcenterID) {
      return this.editForm.controls;
    } else {
      return this.newForm.controls;
    }
  }

  /**
   * 登録ボタンを押された時の処理
   *
   * 入力エラーがある場合はそのままreturn
   * アラートを表示後、新規登録・編集をID有無で判別して、
   * 該当の機能を呼び出し
   *
   */
  async submitForm() {

    this.isSubmitted = true;

    // 新規登録の時
    if (!this.callcenterID && !this.newForm.valid) {
      return false;
    }

    // 編集の時
    if (this.callcenterID && !this.editForm.valid) {
      return false;
    }

    // アラート表示
    const alert = await this.alertController.create({
      header: this.callcenterID ? '編集' : '　新規登録',
      message: this.callcenterID ? 'コールセンターの情報を変更します' : 'コールセンターを新規に登録します',
      buttons: [
        {
          text: 'キャンセル',
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: 'Ok',
          handler: () => {
            if (this.callcenterID) {
              this.editCallcenter();
            } else {
              this.newCallcenter();
            }
          }
        }
      ]
    });
    await alert.present();
  }

  /**
   * コールセンターを追加する
   *
   */
  async newCallcenter() {

    // くるくる
    let loading = await this.loadingController.create();
    await loading.present();

    this.callcenterService.newCallCenter(this.newForm.value).subscribe(
      res => {
        loading.dismiss();
        const response = res as NEW_CALLCENTER_RESPONSE;

        if (response.returnCode === SystemConst.ReturnCode.SUCCESS) {
          this.showToast("登録しました");
          this.modalController.dismiss({ callcenterID: response.user_id });
        }
      },
      error => {
        loading.dismiss();
        console.log("ログ：error：newCallCenter", error);
      }
    );
  }

  /**
   * コールセンターを編集する
   *
   */
  async editCallcenter() {

    // パスワードの変更がない時はキーを削除
    const tmp: EDIT_CALLCENTER_POST = this.editForm.value;
    if (!tmp.password || tmp.password === '') {
      delete tmp['password'];
    }

    // くるくる
    let loading = await this.loadingController.create();
    await loading.present();

    this.callcenterService.editCallCenter(this.callcenterID, this.editForm.value).subscribe(
      res => {
        loading.dismiss();
        const response = res as COMMON_RESPONSE;

        if (response.returnCode === SystemConst.ReturnCode.SUCCESS) {
          this.showToast("変更しました");
          this.modalController.dismiss({ callcenterID: this.callcenterID });
        }
      },
      error => {
        loading.dismiss();
        console.log("ログ：error：editCallCenter", error);
      }
    );
  }

  /**
   * 登録完了のトーストを表示
   *
   * @param { string } msg 表示メッセージ
   *
   */
  async showToast(msg: string) {
    const toast = await this.toastController.create({
      color: 'tertiary',
      position: 'middle',
      message: msg,
      duration: 2000
    });
    toast.present();
  }

  /**
   * 画面を閉じる
   *
   */
  close() {
    this.modalController.dismiss();
  }
}
