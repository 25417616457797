import { Component, Input } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { COMPANY_RESPONSE, COMPANY_DETAIL, WORKER_RESPONSE, WORKER } from '../../../interfaces/worker';
import { WorkerService } from '../../../providers/worker.service';

@Component({
  selector: 'app-modal-select-worker',
  templateUrl: './modal-select-worker.page.html',
  styleUrls: ['./modal-select-worker.page.scss'],
})
export class ModalSelectWorkerPage {

  /* パラメータ */
  @Input() required: boolean;           // 選択必須フラグ
  @Input() companyID: number;           // 作業会社ID
  @Input() workerID: number;            // 作業者ID

  /* 画面制御用 */
  companies: COMPANY_DETAIL[] = [];     // 検索結果
  company: COMPANY_DETAIL = null;       // 選択された業者
  companyIndex: number;                 // 会社選択フォーム
  workers: WORKER[] = [];               // 検索結果
  worker: WORKER;                       // 選択された作業者
  unselected: boolean = false;          // 作業者未定フラグ
  searchKey: string = "";               // 検索ワード
  show: number = 100000;                // 表示件数（全件取得したいので暫定10万件）
  page: number = 1;                     // 何ページ目

  /**
   * Constructor
   *
   * @param { LoadingController } loadingController
   * @param { ModalController } modalController
   * @param { WorkerService } workerService
   *
   */
  constructor(
    private loadingController: LoadingController,
    private modalController: ModalController,
    private workerService: WorkerService,
  ) { }

  /**
   * 画面表示時にデータを取得
   *
   */
  async ionViewWillEnter() {

    // 会社のリストを取得してセット
    await this.getCompnayList();

    // 作業者は呼び出し時に指定があった時だけ取得してセット
    if (this.companyID || this.workerID) {
      await this.loadData();
    }
  }

  /**
   * サーバーからデータを取得し、画面制御用の変数に値を設定する
   *
   */
  async loadData() {

    // API設定
    const search = (this.searchKey !== "" ? this.searchKey : null);

    // くるくる
    let loading = await this.loadingController.create();
    await loading.present();

    // サーバーに依頼
    this.workerService.getWorkerList(this.page, this.show, "worker_name_kana", search).subscribe(
      res => {
        const response = res as WORKER_RESPONSE;
        this.workers = response.results;

        // 指定された作業者がいた時
        if (this.workerID) {

          // 会社でフィルタ
          this.workers = this.workers.filter(w => {
            return w.organization.id == this.companyID;
          });

          // 作業者を選択
          for (var i = 0; i < this.workers.length; i++) {
            if (this.workers[i].id == this.workerID) {
              this.worker = this.workers[i];
              break;
            }
          }

        } else {

          // 会社が指定されている時はその会社でフィルタ
          if (this.company) {
            this.workers = this.workers.filter(w => {
              return w.organization.id == this.company.id;
            });
          }
        }
        loading.dismiss();
      },
      error => {
        loading.dismiss();
        console.log("ログ：error：getWorkerList", error);
      }
    );
  }

  /**
   * サーバーからデータを取得し、画面制御用の変数に値を設定する
   *
   */
  async getCompnayList() {

    // くるくる
    let loading = await this.loadingController.create();
    await loading.present();

    // サーバーに問い合わせ
    this.workerService.getCompanyList(this.page, this.show, "org_name_kana", null).subscribe(
      res => {
        const response = res as COMPANY_RESPONSE;
        this.companies = response.results;
        // 指定された会社があった時は、その会社を選択状態にする
        if (this.companyID) {
          for (var i = 0; i < this.companies.length; i++) {
            if (this.companies[i].id == this.companyID) {
              this.companyIndex = i;
              this.company = this.companies[i];
              break;
            }
          }
        }
        loading.dismiss();
      },
      error => {
        loading.dismiss();
        console.log("ログ：error：getCompanyList", error);
      }
    );
  }

  /**
   * 特定の作業会社が選択された時の処理
   *
   */
  async changeCompany() {
    this.workerID = null;
    this.worker = null;
    this.company = this.companies[this.companyIndex];
    await this.loadData();
  }

  /**
   * クリックされた依頼の情報を表示用変数に格納
   *
   * @param { WORKER } target 選択された作業者
   *
   */
  showDetail(target: WORKER) {
    this.worker = target;
  }

  /**
   * 選択された作業会社と作業者をセットして、画面を閉じる
   *
   */
  setWorker() {
    this.modalController.dismiss({ worker: this.worker, company: this.company });
  }

  /**
   * 作業会社も作業者も未設定にして、画面を閉じる
   *
   */
  clearCompany() {
    this.modalController.dismiss({ worker: null, company: null });
  }

  /**
   * 作業者は未設定にして、画面を閉じる
   *
   */
  clearWorker() {
    this.modalController.dismiss({ worker: null, company: this.company });
  }

  /**
   * 画面を閉じる
   *
   */
  close() {
    this.modalController.dismiss();
  }
}
