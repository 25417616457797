import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { SystemConst } from '../../../app-constant';
import { EDIT_WORK_POST, REQUEST_RESPONSE, WORK_DETAILS } from '../../../interfaces/task';
import { CUSTOMER } from '../../../interfaces/customer';
import { CustomValidator } from '../../../validators/custom-validator';
import { CustomerService } from '../../../providers/customer.service';
import { MapService } from '../../../providers/map.service';
import { WorkService } from '../../../providers/work.service';
import { ModalCalendarPage } from '../modal-calendar/modal-calendar.page';

@Component({
  selector: 'app-modal-edit-work',
  templateUrl: './modal-edit-work.page.html',
  styleUrls: ['./modal-edit-work.page.scss'],
})
export class ModalEditWorkPage implements OnInit {

  /* パラメータ */
  @Input() work: WORK_DETAILS;            // 作業情報

  /* データ */
  customer: CUSTOMER = null;              // 依頼者
  address_id: number = null;              // 住所ID
  address_index: number = null;           // 選択されている住所のインデックス
  beforeWork: WORK_DETAILS;               // 修正前の作業情報
  beforeAddress_id: number = null;        // 変更前の住所ID

  /* 画面制御 */
  workForm: FormGroup;                    // 作業フォーム
  isSubmitted: boolean = false;           // サブミット済み
  timeList = SystemConst.TIME;            // 作業時間のリスト

  /**
   * Constructor
   *
   * @param { FormBuilder } formBuilder
   * @param { AlertController } alertController
   * @param { LoadingController } loadingController
   * @param { ModalController } modalController
   * @param { CustomerService } customerService
   * @param { MapService } mapService
   * @param { WorkService } workService
   */
  constructor(
    private formBuilder: FormBuilder,
    private alertController: AlertController,
    private loadingController: LoadingController,
    private modalController: ModalController,
    private customerService: CustomerService,
    private mapService: MapService,
    private workService: WorkService
  ) { }

  /**
   * ngOnInit
   * フォームの設定
   *
   */
  ngOnInit() {

    // フォーム
    this.workForm = this.formBuilder.group({
      work_date: [this.work.work_date.toString().substr(0, 10), Validators.required],
      work_time: [this.work.work_date.toString().substr(11, 5), Validators.required],
      address_index: [''],
      country: [this.work.country, Validators.required],
      postal_code: [this.work.postal_code, Validators.compose([Validators.required, Validators.pattern('^[0-9-]+$')])],
      prefecture: [this.work.prefecture, Validators.required],
      address1: [this.work.address1, Validators.required],
      address2: [this.work.address2],
      wages: [this.work.wages, Validators.compose([Validators.min(0), CustomValidator.integer])],
    });
  }

  /**
   * 画面表示時にデータを取得
   *
   */
  ionViewWillEnter() {

    // 変更前の情報を退避
    this.beforeWork = this.work;

    // 住所情報を取得
    this.getCustomer();
  }

  /**
   * サーバーから依頼者データを取得し、画面制御用の変数に値を設定する
   *
   */
  async getCustomer() {

    // くるくる
    let loading = await this.loadingController.create();
    await loading.present();

    // サーバーに問い合わせ
    this.customerService.getCustomerDetail(this.work.support_request.request_customer.id).subscribe(
      res => {
        this.customer = res as CUSTOMER;

        // 作業情報に住所のIDがついてこないので、顧客情報に登録されている内容と同じものを探してそのIDを指定
        const tmp = this.customer.address.filter(tmp => {
          return ((tmp.postal_code === this.work.postal_code) &&
            (tmp.prefecture === this.work.prefecture) &&
            (tmp.address1 === this.work.address1) &&
            (tmp.address2 === this.work.address2));
        });
        if (tmp[0]) {
          this.beforeAddress_id = tmp[0].id;
        } else {
          this.beforeAddress_id = -1; // 楼録住所が見つからない時は新規扱い
          this.workForm.controls.address_index.setValue(this.beforeAddress_id);
          this.address_id = null;
          this.address_index = this.beforeAddress_id;
        }

        // 指定の住所を退避し、選択状態にする
        for (var i = 0; i < this.customer.address.length; i++) {
          if (this.customer.address[i].id == this.beforeAddress_id) {
            this.workForm.controls.address_index.setValue(i);
            this.address_id = this.beforeAddress_id;
            this.address_index = i;
            break;
          }
        }
        loading.dismiss();
      },
      error => {
        loading.dismiss();
        console.log("ログ：error：getCustomerDetail", error);
      }
    );
  }

  /**
   * カレンダーを開く
   *
   */
  async showCalendar() {
    const myCalendar = await this.modalController.create({
      component: ModalCalendarPage,
      cssClass: 'calendar-modal-css'
    });
    myCalendar.present();
    const event: any = await myCalendar.onDidDismiss();
    if (event.data) {
      this.workForm.controls.work_date.setValue(event.data['work_date']);
    }
  }

  /**
   * 住所選択時の処理
   * 登録済み住所が選択された場合はその値を設定
   *
   */
  async checkAddress() {

    //　新規作成が選択された時
    if (this.workForm.controls.address_index.value == -1) {
      this.address_id = null;
      this.workForm.controls.postal_code.setValue(null);
      this.workForm.controls.prefecture.setValue(null);
      this.workForm.controls.address1.setValue(null);
      this.workForm.controls.address2.setValue(null);

    } else {

      // 選択された住所を設定
      this.address_id = this.customer.address[this.workForm.controls.address_index.value].id;

      // フォームで必須チェックが入っているところにデータを入れる
      this.workForm.controls.postal_code.setValue(this.customer.address[this.workForm.controls.address_index.value].postal_code);
      this.workForm.controls.prefecture.setValue(this.customer.address[this.workForm.controls.address_index.value].prefecture);
      this.workForm.controls.address1.setValue(this.customer.address[this.workForm.controls.address_index.value].address1);
      this.workForm.controls.address2.setValue(this.customer.address[this.workForm.controls.address_index.value].address2);
    }
  }

  /**
   * 登録ボタンを押された時の処理
   *
   * 入力エラーがある場合はそのままreturn
   * アラートを表示後、新規登録・編集をID有無で判別して、
   * 該当の機能を呼び出し
   *
   */
  async submitForm() {

    this.isSubmitted = true;

    // エラーがある時は何もしない
    if (!this.workForm.valid) {
      return false;
    }

    // アラート表示
    const alert = await this.alertController.create({
      header: '作業編集',
      message: '作業を編集します',
      buttons: [
        {
          text: 'キャンセル',
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: 'Ok',
          handler: () => {
            this.editWork();
          }
        }
      ]
    });
    await alert.present();
  }

  /**
   * 作業を編集する
   *
   */
  async editWork() {

    // ポストデータ
    var postData: EDIT_WORK_POST = {};

    // 作業日時
    const afterWork_date: string = this.workForm.value.work_date + 'T' + this.workForm.value.work_time + ':00+09:00';
    if (afterWork_date !== this.beforeWork.work_date.toString()) {
      postData.work_date = afterWork_date;
    }

    // 作業場所
    if (this.address_id && this.address_id !== this.beforeAddress_id) {

      // 登録済みの他の住所に変更
      postData.address_id = this.address_id;
      postData.edit_address_mode = 'change';


    } else if (!this.address_id) {

      // 新規登録
      postData.edit_address_mode = 'new';
      postData.country = this.workForm.value.country;
      postData.postal_code = this.workForm.value.postal_code;
      postData.prefecture = this.workForm.value.prefecture;
      postData.address1 = this.workForm.value.address1;
      if (this.workForm.value.address2) {
        postData.address2 = this.workForm.value.address2;
      }

      // ジオコーディング
      const result = await this.mapService.geocoding((this.workForm.value.prefecture + this.workForm.value.address1) + (this.workForm.value.address2 ? this.workForm.value.address2 : ""));
      if (result) {
        let location = result[0].geometry.location;
        postData.latitude = location.lat();
        postData.longitude = location.lng();
      }

    } else if (this.address_id === this.beforeAddress_id) {

      // もともと選択されていた作業場所の内容を変更
      var editFlag = false;

      // 郵便番号が変更された時
      if (this.beforeWork.postal_code !== this.workForm.value.postal_code) {
        postData.postal_code = this.workForm.value.postal_code;
        editFlag = true;
      }

      // 都道府県が変更された時
      if (this.beforeWork.prefecture !== this.workForm.value.prefecture) {
        postData.prefecture = this.workForm.value.prefecture;
        editFlag = true;
      }

      // 市区町村が変更された時
      if (this.beforeWork.address1 !== this.workForm.value.address1) {
        postData.address1 = this.workForm.value.address1;
        editFlag = true;
      }

      // 番地以下が変更された時
      if (this.beforeWork.address2 !== this.workForm.value.address2) {
        postData.address2 = this.workForm.value.address2;
        editFlag = true;
      }

      // ここまでで変更があった時だけ緯度経度取得
      if (editFlag) {

        // ジオコーディング
        const result = await this.mapService.geocoding((this.workForm.value.prefecture + this.workForm.value.address1) + (this.workForm.value.address2 ? this.workForm.value.address2 : ""));
        if (result) {
          let location = result[0].geometry.location;

          // 緯度が変更された時
          if (this.beforeWork.latitude !== location.lat()) {
            postData.latitude = location.lat();
          }

          // 経度が変更された時
          if (this.beforeWork.longitude !== location.lng()) {
            postData.longitude = location.lng();
          }
        }
      }

      if (editFlag) {
        postData.address_id = this.address_id;
        postData.edit_address_mode = 'edit';
      }
    }

    // 代金
    if (this.beforeWork.wages !== this.workForm.value.wages) {
      postData.wages = this.workForm.value.wages;
    }

    // くるくる
    let loading = await this.loadingController.create();
    await loading.present();

    this.workService.editWork(this.work.id, postData).subscribe(
      res => {
        loading.dismiss();
        const response = res as REQUEST_RESPONSE;
        if (response.returnCode === SystemConst.ReturnCode.SUCCESS) {
          this.modalController.dismiss({ workID: this.work.id });
        }
      },
      error => {
        loading.dismiss();
        console.log("ログ：error：editWork", error);
      }
    );
  }

  /**
   * 画面を閉じる
   *
   */
  close() {
    this.modalController.dismiss();
  }
}
