import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { NEW_SUPPORT_POST, EDIT_SUPPORT_POST } from '../interfaces/task';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root'
})
export class SupportService {

  /* 通信先 */
  url = environment.apiUrl;

  /**
   * Constructor
   *
   * @param { HttpClient } httpClient
   * @param { AlertService } alertService
   */
  constructor(
    private httpClient: HttpClient,
    private alertService: AlertService
  ) { }

  /**
   * サーバーから依頼一覧を取得
   * ソート：id, subject, status, request_customer__customer_name, request_customer__customer_name_kana, create_date, update_date
   * 検索：subject, details, request_customer__customer_name, request_customer__customer_name_kana
   * 戻り：SUPPORT_RESPONSE
   * 対象：WEB ○ / APP ○
   *
   * @param { number } p            ページ番号(1〜)
   * @param { number } s            1ページあたりの取得数
   * @param { string } ordering     表示順
   * @param { string } search       検索文字列
   * @param { string } status       検索文字列
   * @returns { Observable<any> }   結果
   * @throws { any }                エラーメッセージ
   */
  getSupportList(p?: number, s?: number, ordering?: string, search?: string, status?: string): Observable<any> {

    var params = new HttpParams();

    // ページ番号(1〜)
    if (p) {
      params = params.set('p', p.toString());
    }

    // 1ページあたりの取得数
    if (s) {
      params = params.set('s', s.toString());
    }

    // 並び替えカラム名
    if (ordering) {
      params = params.set('ordering', ordering);
    }

    // 検索条件
    if (search) {
      params = params.set('search', search);
    }

    // ステータス
    if (status) {
      params = params.set('status', status);
    }

    return this.httpClient.get(`${this.url}/supportrequest/`, { params })
      .pipe(
        catchError(
          error => {
            this.alertService.showAlert(error);
            throw error;
          }
        )
      );
  }

  /**
   * 依頼情報詳細を取得
   *
   * 戻り：SUPPORT_DETAILS
   * 対象：WEB ○ / APP ○
   *
   * @param { number } supportID        依頼ID
   * @returns { Observable<any> }       結果
   * @throws { any }                    エラーメッセージ
   */
  getSupportDetail(supportID: number): Observable<any> {
    return this.httpClient.get<any>(`${this.url}/supportrequest/${supportID}`)
      .pipe(
        catchError(
          error => {
            this.alertService.showAlert(error);
            throw error;
          }
        )
      );
  }

  /**
   * 依頼を新規作成する
   *
   * 戻り：NEW_SUPPORT_RESPONSE
   * 対象：WEB ○ / APP ×
   *
   * @param { NEW_SUPPORT_POST } postdata ポストデータ
   * @returns { Observable<any> }         結果
   * @throws { any }                      エラーメッセージ
   */
  newSupport(postdata: NEW_SUPPORT_POST): Observable<any> {
    return this.httpClient.post<any>(`${this.url}/supportrequest/new`, postdata)
      .pipe(
        catchError(
          error => {
            this.alertService.showAlert(error);
            throw error;
          }
        )
      );
  }

  /**
   * 依頼を編集する
   *
   * 戻り：COMMON_RESPONSE
   * 対象：WEB ○ / APP ×
   *
   * @param { number } supportID            依頼ID
   * @param { EDIT_SUPPORT_POST } postdata  ポストデータ
   * @returns { Observable<any> }           結果
   * @throws { any }                        エラーメッセージ
   */
  editSupport(supportID: number, postdata: EDIT_SUPPORT_POST): Observable<any> {
    return this.httpClient.post<any>(`${this.url}/supportrequest/${supportID}/edit`, postdata)
      .pipe(
        catchError(
          error => {
            this.alertService.showAlert(error);
            throw error;
          }
        )
      );
  }

  /**
   * サーバーからカテゴリー一覧を取得
   * ソート：id
   * 検索：name
   * 戻り：CATEGORY_RESPONSE
   * 対象：WEB ○ / APP ×
   *
   * @param { number } p            ページ番号(1〜)
   * @param { number } s            1ページあたりの取得数
   * @param { string } ordering     表示順
   * @param { string } search       検索文字列
   * @returns { Observable<any> }   結果
   * @throws { any }                エラーメッセージ
   */
  getCategoryList(p?: number, s?: number, ordering?: string, search?: string): Observable<any> {

    var params = new HttpParams();

    // ページ番号(1〜)
    if (p) {
      params = params.set('p', p.toString());
    }

    // 1ページあたりの取得数
    if (s) {
      params = params.set('s', s.toString());
    }

    // 並び替えカラム名
    if (ordering) {
      params = params.set('ordering', ordering);
    }

    // 検索条件
    if (search) {
      params = params.set('search', search);
    }

    return this.httpClient.get(`${this.url}/supportrequest/category`, { params })
      .pipe(
        catchError(
          error => {
            this.alertService.showAlert(error);
            throw error;
          }
        )
      );
  }
}
