import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

import { AuthorizedDirective } from './authorized.directive';

@NgModule({
  declarations: [
    AuthorizedDirective
  ],

  imports: [
    IonicModule,
    CommonModule
  ],

  exports: [
    AuthorizedDirective
  ]
})
export class SharedDirectivesModule { }
