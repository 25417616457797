import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appAuthorized]'
})
export class AuthorizedDirective implements OnInit {

  /**
   * パラメータ：表示可能な役割
   *
   * @type {string[]}
   * @memberof AuthorizedDirective
   */
  @Input() public enabled: string[];

  /**
   * パラメータ：ユーザーの持つ権限
   *
   * @type {string[]}
   * @memberof AuthorizedDirective
   */
  @Input() public roles: string[];

  /**
   * コンストラクタ
   *
   * @param {ElementRef} elementRef このディレクティブがセットされたDOM
   * @memberof AuthorizedDirective
   */
  constructor(
    private elementRef: ElementRef
  ) { }

  /**
   * ngOnInit
   * 自分が保持する権限に、利用可能な権限に自分の権限が
   * 含まれているか確認し、権限がなかった時は非表示にする
   *
   * @memberof AuthorizedDirective
   */
  public ngOnInit() {

    var show: boolean = false;

    for (var i = 0; i < this.roles.length; i++) {
      var result = this.enabled.indexOf(this.roles[i]);
      if (result != -1) {
        show = true;
        break;
      }
    }

    if (!show) {
      const element = this.elementRef.nativeElement;
      element.style.display = "none";
    }
  }
}
