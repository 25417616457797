import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ModalEditWorkPageRoutingModule } from './modal-edit-work-routing.module';

import { ModalEditWorkPage } from './modal-edit-work.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    ModalEditWorkPageRoutingModule
  ],
  declarations: [ModalEditWorkPage]
})
export class ModalEditWorkPageModule { }
