import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { NEW_ADDRESS_POST, NEW_CUSTOMER_POST, EDIT_ADDRESS_POST, EDIT_CUSTOMER_POST } from '../interfaces/customer';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  /* 通信先 */
  url = environment.apiUrl;

  /**
   * Constructor
   *
   * @param { HttpClient } httpClient
   * @param { AlertService } alertService
   */
  constructor(
    private httpClient: HttpClient,
    private alertService: AlertService
  ) { }

  /**
   * サーバーから依頼者一覧を取得
   * ソート：email, customer_name, customer_name_kana
   * 検索：email, customer_name, customer_name_kana
   * 戻り：CUSTOMER_RESPONSE
   * 対象：WEB ○ / APP ○
   *
   * @param { number } p            ページ番号(1〜)
   * @param { number } s            1ページあたりの取得数
   * @param { string } ordering     表示順
   * @param { string } search       検索文字列
   * @returns { Observable<any> }   結果
   * @throws { any }                エラーメッセージ
   */
  getCustomerList(p?: number, s?: number, ordering?: string, search?: string): Observable<any> {

    var params = new HttpParams();

    // ページ番号(1〜)
    if (p) {
      params = params.set('p', p.toString());
    }

    // 1ページあたりの取得数
    if (s) {
      params = params.set('s', s.toString());
    }

    // 並び替えカラム名
    if (ordering) {
      params = params.set('ordering', ordering);
    }

    // 検索条件
    if (search) {
      params = params.set('search', search);
    }

    return this.httpClient.get(`${this.url}/user/customer`, { params })
      .pipe(
        catchError(
          error => {
            this.alertService.showAlert(error);
            throw error;
          }
        )
      );
  }

  /**
   * 依頼者情報詳細を取得
   *
   * 戻り：CUSTOMER
   * 対象：WEB ○ / APP ○
   *
   * @param { number } customerID      依頼者ID
   * @returns { Observable<any> }       結果
   * @throws { any }                    エラーメッセージ
   */
  getCustomerDetail(customerID: number): Observable<any> {
    return this.httpClient.get<any>(`${this.url}/user/customer/${customerID}`)
      .pipe(
        catchError(
          error => {
            this.alertService.showAlert(error);
            throw error;
          }
        )
      );
  }

  /**
   * 依頼者を新規作成する
   *
   * 戻り：NEW_CUSTOMER_RESPONSE
   * 対象：WEB ○ / APP ×
   *
   * @param { NEW_CUSTOMER_POST } postdata  ポストデータ
   * @returns { Observable<any> }             結果
   * @throws { any }                          エラーメッセージ
   */
  newCustomer(postdata: NEW_CUSTOMER_POST): Observable<any> {
    return this.httpClient.post<any>(`${this.url}/user/customer/new`, postdata)
      .pipe(
        catchError(
          error => {
            this.alertService.showAlert(error);
            throw error;
          }
        )
      );
  }

  /**
   * 依頼者を編集する
   *
   * 戻り：COMMON_RESPONSE
   * 対象：WEB ○ / APP ×
   *
   * @param { number } customerID               依頼者ID
   * @param { EDIT_CUSTOMER_POST } postdata     ポストデータ
   * @returns { Observable<any> }               結果
   * @throws { any }                            エラーメッセージ
   */
  editCustomer(customerID: number, postdata: EDIT_CUSTOMER_POST): Observable<any> {
    return this.httpClient.post<any>(`${this.url}/user/customer/${customerID}/edit`, postdata)
      .pipe(
        catchError(
          error => {
            this.alertService.showAlert(error);
            throw error;
          }
        )
      );
  }

  /**
   * 依頼者の住所を新規作成する
   *
   * 戻り：COMMON_RESPONSE
   * 対象：WEB ○ / APP ×
   *
   * @param { number } customerID             依頼者ID
   * @param { NEW_ADDRESS_POST } postdata     ポストデータ
   * @returns { Observable<any> }             結果
   * @throws { any }                          エラーメッセージ
   */
  newAddress(customerID: number, postdata: NEW_ADDRESS_POST): Observable<any> {
    return this.httpClient.post<any>(`${this.url}/user/${customerID}/address/new`, postdata)
      .pipe(
        catchError(
          error => {
            this.alertService.showAlert(error);
            throw error;
          }
        )
      );
  }

  /**
   * 依頼者の住所を編集する
   *
   * 戻り：COMMON_RESPONSE
   * 対象：WEB ○ / APP ×
   *
   * @param { number } customerID               依頼者ID
   * @param { number } addressID                住所ID
   * @param { EDIT_ADDRESS_POST } postdata      ポストデータ
   * @returns { Observable<any> }               結果
   * @throws { any }                            エラーメッセージ
   */
  editAddress(customerID: number, addressID: number, postdata: EDIT_ADDRESS_POST): Observable<any> {
    return this.httpClient.post<any>(`${this.url}/user/${customerID}/address/${addressID}/edit`, postdata)
      .pipe(
        catchError(
          error => {
            this.alertService.showAlert(error);
            throw error;
          }
        )
      );
  }

  /**
   * 依頼者の住所を削除する
   *
   * 戻り：COMMON_RESPONSE
   * 対象：WEB ○ / APP ×
   *
   * @param { number } customerID               依頼者ID
   * @param { number } addressID                住所ID
   * @returns { Observable<any> }               結果
   * @throws { any }                            エラーメッセージ
   */
  deleteAddress(customerID: number, addressID: number): Observable<any> {
    return this.httpClient.post<any>(`${this.url}/user/${customerID}/address/${addressID}/delete`, null)
      .pipe(
        catchError(
          error => {
            this.alertService.showAlert(error);
            throw error;
          }
        )
      );
  }
}
