import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-preview',
  templateUrl: './modal-preview.page.html',
  styleUrls: ['./modal-preview.page.scss'],
})
export class ModalPreviewPage {

  /* パラメータ */
  @Input() imageData: number;        // イメージデータ

  /**
   * Constructor
   *
   * @param { ModalController } modalController
   *
   */
  constructor(
    private modalController: ModalController
  ) { }

  /**
   * 画面を閉じる
   *
   */
  close() {
    this.modalController.dismiss();
  }
}
