import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  /**
   * Constructor
   *
   * @param { Router } router
   * @param { AuthService } authService
   */
  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  /**
   * ログイン済みの時だけ表示する画面の表示可否
   * AuthServiceにログイン状態を問い合わせ、
   * ログイン済みの場合True,未ログインはFalseを返却
   * 
   * @return boolean | Promise<boolean>
   */
  canActivate(): boolean | Promise<boolean> {

    return new Promise((resolve, reject) => {
      let status: boolean = this.authService.isAuthenticated();

      // ログイン状態が未設定（初期値null)の場合は、先にログイン状態を設定
      if (status === null) {

        // ログイン状態設定
        this.authService.checkToken().then(
          ret => {
            status = this.authService.isAuthenticated();

            if (status) {
              // ログイン済みの時はtureを返却
              resolve(true);
            } else {
              // 未ログインの時はログイン画面を表示して、falseを返却
              this.router.navigate(['login']);
              resolve(false);
            }
          }
        );

      } else if (status === true) {
        // ログイン済みの時はtureを返却
        resolve(true);
      } else {
        // 未ログインの時はログイン画面を表示して、falseを返却
        this.router.navigate(['login']);
        resolve(false);
      }
    });
  }
}
