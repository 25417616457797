import { Component, OnInit } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';

import { ReportService } from '../../../providers/report.service';
import { ITEM_RESPONSE, TEMPLATE_CHECKITEM } from '../../../interfaces/report';

@Component({
  selector: 'app-modal-select-checkitem',
  templateUrl: './modal-select-checkitem.page.html',
  styleUrls: ['./modal-select-checkitem.page.scss'],
})
export class ModalSelectCheckitemPage implements OnInit {

  /* データ */
  items: TEMPLATE_CHECKITEM[] = null;

  /* 画面制御 */
  checked: boolean[];
  selected: TEMPLATE_CHECKITEM[] = null;

  /**
   * Constructor
   *
   * @param { LoadingController } loadingController
   * @param { ModalController } modalController
   * @param { ReportService } reportService
   *
   */
  constructor(
    private loadingController: LoadingController,
    private modalController: ModalController,
    private reportService: ReportService,
  ) {
  }

  ngOnInit() {
  }

  /**
   * 画面表示時にデータを取得
   *
   */
  async ionViewWillEnter() {

    // くるくる
    let loading = await this.loadingController.create();
    await loading.present();

    // サーバーに問い合わせ
    // 全件取得したいため、1000を暫定的に指定
    this.reportService.getCheckItemList(1, 1000, null, null).subscribe(
      res => {
        const response = res as ITEM_RESPONSE;
        this.items = response.results;
        this.checked = new Array(this.items.length);
        loading.dismiss();
      },
      error => {
        loading.dismiss();
        console.log("ログ：error：getCheckItemList", error);
      }
    );
  }

  /**
   * クリックされた選択項目を返却して画面を閉じる
   *
   */
  checkedItems() {

    // 初期化
    this.selected = new Array();

    for (var i = 0; i < this.checked.length; i++) {
      if (this.checked[i]) {
        this.selected.push(this.items[i]);
      }
    }
    this.modalController.dismiss({ items: this.selected });
  }

  /**
   * 画面を閉じる
   *
   */
  close() {
    this.modalController.dismiss();
  }
}
